import React, { useRef, useEffect } from "react"
import {Link, navigate} from 'gatsby';
import Layout from "../components/layout"
import styled from 'styled-components';
import gsap from 'gsap'
import TextPlugin from 'gsap/TextPlugin';

import Logo from "../images/create-logo.png";
import BracketUp from "../images/bracket-up.png";
import BracketDown from "../images/bracket-down.png";


import RowWrapper from "../utils/rowWrapper"

import Nav from '../components/nav/nav';
import Contact from '../components/contact-button';

gsap.registerPlugin(TextPlugin);


const Container = styled.section`
  height: 100vh;
  padding-top: 5rem;
  position: relative;
`
const LogoContainer = styled.div`
  width: 8rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
`

const Heading = styled.h1`
  visibility: hidden;
  &, & * {
    text-transform: uppercase;
    font-family: "Museo Sans Rounded 900", sans-serif;
    font-size: 4rem;
    text-align: center;
    margin: 10rem 0;
    cursor: pointer;
    transition: color .2s ease-out;
    /* will-change: transform; */
    backface-visibility: hidden;
    /* background-color: green; */
  }

  span:hover {
    color: ${props => props.theme.colors.color};
    
  }

  @media ${props => props.theme.breaks.down('md')} {
    &, & * {
    line-height: 4.5rem;
    font-size: 3rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
   

    &, & * {
      width: 17rem;
      margin: 3rem auto;
      text-align: center;
      font-size: 3.2rem;
      line-height: 3.8rem;
      line-break: loose;
    }
  }

`

const Center = styled.div`
  width: 80%;
  margin: 10% auto 0 auto;


  ul li a {
    color: #fff;
    visibility: hidden;
  }
/*   
  &:hover #bracket-down {
    top: -3px;
  }

  &:hover #bracket-up{
    top: 3px;
  } */
 
  @media ${props => props.theme.breaks.down('sm')} {
    /* margin: 15% auto 0 auto; */
  }
`

const Bracket = styled.div`
  width: 15rem;
  margin: 0 auto;
  position: relative;
  visibility: hidden;
  /* transition: top .3s ease; */

  img {
    width: 100%;
  }
`

const Explain = styled.h5`
  font-size: 1.6rem;
  font-family: "Museo Sans Rounded 100", sans-serif;
  letter-spacing: 6px;
  text-align: center;
  color: rgba(0, 0, 0, .6);
  position: absolute;
  width: 100%;
  bottom: 8rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.2rem;
    /* bottom: 4.5rem; */
    left: 0;
    transform: none;
    position: relative;
    bottom: 0;
    width: 100%;
    letter-spacing: 2px;
    margin-top: 3rem;
  }
`

const NavContainer = styled.div`
  position: fixed;
  top: 5rem;
  left: 80%;
  z-index: 100;
  display: none;
`

// markup
const IndexPage = () => {

  const cen = useRef()
  const bu = useRef()
  const bd = useRef()
  const h1 = useRef()
  const ex = useRef()

  const sendTo = link => {
    document.getElementById("bracket-down").classList.remove("pulse")
    document.getElementById("bracket-up").classList.remove("pulse")
    
    const tl = gsap.timeline({
      onComplete: () => {
        document.body.style.overflowY = "auto"
        navigate(link)
      }
    });

    tl.to(h1.current, {autoAlpha: 0, scale: .3, duration: .5, ease: "ease-out"})
    .addLabel("brackets")
    .to(bd.current, {y: 100, duration: .5, ease: "ease-in"}, "brackets")
    .to(bu.current, {y: -100, duration: .5, ease: "ease-in"}, 'brackets')
    .addLabel("fade-out")
    .to(bd.current, {autoAlpha: 0, duration: .5, ease: "ease-in"}, "fade-out")
    .to(bu.current, {autoAlpha: 0, duration: .5, ease: "ease-in"}, 'fade-out')
  }

  useEffect(() => {
    document.body.style.overflowY = "hidden"
    h1.current.style.willChange = "transform"

    const tl = gsap.timeline({
      onComplete: () => {
        document.getElementById("bracket-down")?.classList.add("pulse")
        document.getElementById("bracket-up")?.classList.add("pulse")
        if (h1.current)
          h1.current.style.willChange = ""
      }
    });

    tl.set("#bracket-down", {y: 100, scale: .3})
    .set("#bracket-up", {y: -100, scale: .3})
    .set(h1.current, {scale: .3})
    .addLabel('fade')
    .to(bd.current, {autoAlpha: 1, y: 110, scale: 1, duration: 1}, 'fade+=.2')
    .to(bu.current, {autoAlpha: 1, y: -110, scale: 1, duration: 1}, 'fade+=.2')
    .to(cen.current, {rotate: 360, duration: 1}, "fade+=.2")
    .addLabel("enter")
    .to(bd.current, {y: 0, duration: 1.2, delay: .3, ease: "ease-in"}, "enter")
    .to(bu.current, {y: 0, duration: 1.2, delay: .3, ease: "ease-in"}, 'enter')
    .to(h1.current, {autoAlpha: 1, scale: 1, duration: 1.2, ease: "ease-in"})
    // .to(ex.current, {text: {value: "CLICK ON A WORD TO LEARN MORE", delimiter: " ", speed: 1}, duration: 2, ease: "ease-out"})

  }, [])

  return (
    <Layout pageTitle="Create A Space">
      <Container>

      <NavContainer>
        <Nav />
      </NavContainer>

        <RowWrapper>
          <LogoContainer>
          <Link to="/"><img src={Logo} alt="Create A Space Logo" /></Link>
          </LogoContainer>
          <Center ref={cen}>
            <Bracket ref={bd} className="bracket" id="bracket-down">
              <img src={BracketDown} alt="bracket facing down" />
            </Bracket>

            <nav>
              <ul style={{height: "2px"}}>
                <li><Link to="/we">We</Link></li>
                <li><Link to="/complete">Complete</Link></li>
                <li><Link to="/you">You</Link></li>
                <li><Link to="/with">With</Link></li>
                <li><Link to="/closets">Closets</Link></li>
              </ul>

            <Heading ref={h1} className="index-h1">
              <span role="link" tabIndex="0" 
              onClick={() => sendTo("/we")} 
              onKeyDown={() => sendTo("/we")}>We </span> 

             <span role="link" tabIndex="0" 
             onClick={() => sendTo("/complete")} 
             onKeyDown={() => sendTo("/complete")}>Complete </span>  

             <span role="link" tabIndex="0" 
             onClick={() => sendTo("/you")} 
             onKeyDown={() => sendTo("/you")}>You </span>

              (
              <span role="link" tabIndex="0" 
              onClick={() => sendTo("/with")} 
              onKeyDown={() => sendTo("/with")}>With </span>

              <span role="link" tabIndex="0" 
              onClick={() => sendTo("/closets")} 
              onKeyDown={() => sendTo("/closets")}>Closets</span>
              )</Heading>

            </nav>

            <Bracket ref={bu} className="bracket" id="bracket-up">
              <img src={BracketUp} alt="bracket facing up" />
            </Bracket>
          </Center>

        </RowWrapper>

        <Explain ref={ex}>CLICK ON A WORD TO LEARN MORE</Explain>

        <Contact pos="7rem" />
        
      </Container>
    </Layout>
  )
}

export default IndexPage
