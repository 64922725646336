import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Phone from '../images/phone.svg'

const ContactContainer = styled.div`
    position: ${props => props.pos ? "absolute" : "relative"};
    bottom: ${props => props.pos ? props.pos : "0"};
    left: ${props => props.left ? props.left : props.pos ? "10%" : "0"};
    width: 20rem;
    z-index: 100;

    @media ${props => props.theme.breaks.down('lg')} {
      position: fixed;
      bottom: 4rem;
      right: 1rem;
      left: auto;
      top: auto;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 1rem 1.5rem;
      background-color: ${props => props.theme.colors.color};
      display: ${props => props.visible ? "block" : "none"};
    }
 `
  const Contact = styled(Link)`
    font-size: 1.6rem;
    text-transform: uppercase;
    font-family: "Museo Sans Rounded 700", sans-serif;
    color: ${props => props.color === "white" ? props.theme.colors.body : props.theme.colors.color};
    border: 1px solid ${props => props.color === "white" ? props.theme.colors.body : props.theme.colors.color};
    padding: 1rem 3rem;
    display: inline-block;
    transition: transform .2s ease;
    /* backface-visibility: hidden; */

    span {
      font: inherit;
      color: inherit;

      img {
        width: 2rem;
      }
    }

    
    &:hover {
      transform: scale(1.03);
    }

    @media ${props => props.theme.breaks.down('lg')} {
      padding: 0;
      color: ${props => props.theme.colors.body};
      border: none;

      .desk-contact {
        display: none;
      }
    }

    @media ${props => props.theme.breaks.up('lg')} {
      .mobile-contact {
        display: none;
      }
    }
  `
 
const ContactButton = ({type, pos, color, left}) => {

  const [visible, setVisible] = useState(false);

  const listener = () => {
    const currScroll = window.scrollY;

    if (currScroll > 50)
      setVisible(true)
    else
      setVisible(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener);
    
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [])

 
 return (
  <ContactContainer className="pulse-shadow" visible={visible} type={type} pos={pos} left={left}>
    <Contact to="/contact-us" color={color}>
      <span className="desk-contact">Contact Us</span>
      <span className="mobile-contact"><img src={Phone} alt="" /></span>
    </Contact>
  </ ContactContainer>
 )
};

export default ContactButton;